import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { BiLinkExternal } from 'react-icons/Bi';
import 'react-app-polyfill/ie11';

class fdaMonographList extends  React.Component {


	constructor( props ) {
		super( props );




		this.state = {
			loading: true,
			pagesize: 10,
			page: 1,
			splData: {},
			totalResults: 0,
			message: '',

		};	
	}

	componentDidMount() {
		document.title = "FDA Monographs | PIMsPlus";

		const {pagesize, page} = this.state;

		var path = 'http://undefined';
		if (typeof window !== 'undefined') {
			// API is part of plugin, so it's always self-hosted
   			path = window.location.origin;
  		}

		const api_url = path + '/wp-json/pimsplus/v1/dailymedspls';

		const searchUrl = api_url + '?rxcui=' + this.props.match.params.rxcui
			+ `&pagesize=${pagesize}&page=${page}`; 
	
		//alert(dailymedSearchUrl);
		//
		//console.log(dailymedSearchUrl);

                axios.get(searchUrl)
                        .then((res) => {
                        this.setState({
                                splData: res.data,
                                loading: false,
                        });
                })
                .catch((error) => {
                        if ((error.response || error.request) && (axios.isCancel(error) || error)) {
                                console.log(error);
                                this.setState({
                                        loading: false,
                                        message: 'Failed to fetch results, please try again later.',
                                        //message: searchUrl,
                                });
                        }
                });
	}

	renderSearchResults = () => {
		const { splData } = this.state;
		if ( Object.keys(splData).length && splData.metadata.total_elements ) {
			return (
				<React.Fragment>
				{splData.data.map((matchingspl) =>
 					{ return(
 					<React.Fragment>
						<p>
							<b>Title: </b>&nbsp;{matchingspl.title} <br />
							<b>Monograph: </b>&nbsp;
							<Link to={{pathname: 'https://api.tapermd.org/product-monographs/index.php',
									search: `?setid=${matchingspl.setid}`}}
								target="_blank">
								Open now <BiLinkExternal />
							</Link><br />
							<b>Published: </b>&nbsp;{matchingspl.published_date}
						</p>
						
					</React.Fragment>)
                                      	}
				)}
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					No matching monograpghs found. Try a different from of this drug.
				</React.Fragment>
			);
		}	
	};

	render() {
		const { loading } = this.state;

		if (loading) {
			return (<p>Loading ...</p>);
		}

		return (
			<div className="monographListing">
				{ this.renderSearchResults() }
			</div>
		)
	}
}

export default fdaMonographList;
