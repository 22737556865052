import 'react-app-polyfill/ie11';
//import 'core-js/features/object';
//import '../polyfill.js';

import React from 'react';
import './drugDetail.css';
import axios from 'axios';
import { Link } from 'react-router-dom'
import { BiLinkExternal, BiInfoCircle } from 'react-icons/Bi';

class DrugDetail extends  React.Component {


	constructor( props ) {
		super( props );

		this.state = {
			drugName: '', // This is kind of crazy, but the drug name is actually our true identifier
			loading: true,
			blackBoxWarning: false,
			drugData: {},
			drugDataLoaded: false,
			drugFormsToLoad: 2, 
			medlineLoading: true,
			medlineData: {},
			medlineMainTitle: '',
			medlineMainSummary: '',
			medlineExpandSummary: false,
			pims: [],
			pimsLoading: true,
			showReferences: false,
			taperRecs: [],
		};	
	}

	componentWillMount() {
		if ( this.props.location.state !== undefined && this.props.location.state.query !== '' )
			this.setState( this.props.location.state );
	}

	// Decodes drug name from the URI.
	getDrugName = (name) => {
		return decodeURIComponent(name).replace(/_and_/g,'/').replace(/___/g,' ');

	}

	componentDidMount() {
		const drugName = this.getDrugName(this.props.match.params.drugName);
		document.title = drugName + " | PIMsPlus";
		document.getElementById("drug-detail-pagetitle").scrollIntoView();

		// If data was pulled from local cached history, no need to re-load!
		// However if user used bookmark, shared link, etc, have to load everything again
		if (!this.state.loading) return; // If data was pulled from local cached history, no need to re-load!
                //var path = 'http://undefined';
		const api_key = '8E660ab9-da8a-46bd-8be2-5f7bfc42ca15'; // Doesn't matter if this is public
		//const passedvalues = queryString.parse(this.props.location.search);


                //if (typeof window !== 'undefined') {
                        // API is part of plugin, so it's always self-hosted
                //        path = window.location.origin;
                //}

	
		//var parsedDrugData = JSON.parse(passedvalues.drugdata);


/*****************************/

                const search_api_url = '/wp-json/pimsplus/v1/drugs';

                const searchUrl = `${search_api_url}?api_key=${api_key}&q=${drugName}&page=1&perpage=1&genericsOnly=0`;

		if (!this.state.drugDataLoaded) {
	                axios.get(searchUrl)
        	                .then((res) => {
                	        this.setState({
					drugName: drugName,
					drugDataLoaded: true,
					drugData: res.data.drugs[0],
        	                }, this.loadAdditionalDisplayData);
                	})
		}
		else {
			this.loadAdditionalDisplayData();
		}

/*****************************/


	}

	loadAdditionalDisplayData = () => {
		const { drugData } = this.state;
		const api_key = '8E660ab9-da8a-46bd-8be2-5f7bfc42ca15'; // Doesn't matter if this is public

                var path = 'http://undefined';
                if (typeof window !== 'undefined') {
                        // API is part of plugin, so it's always self-hosted
                        path = window.location.origin;
                }

		const pim_api_url = path + '/wp-json/pimsplus/v1/pims'
		const pimBaseSearchUrl = `${pim_api_url}?api_key=${api_key}`;

		const pimSearchUrl = pimBaseSearchUrl + '&rxcui=' + drugData.matchingDrugs[0].rxcui;

		axios.get(pimSearchUrl)
                        .then((res) => {
				const allPims = [];
				res.data.pims.forEach(datum => {
					allPims.push(datum);
				});

				const allTaperRecs = [];
				res.data.taper_recs.forEach(datum => {
					allTaperRecs.push(datum);
				});

				if ( ! (res.data.blackBoxWarningFlag === undefined) ) {
					this.setState({
						blackBoxWarning: res.data.blackBoxWarningFlag,
					});
				}

				this.setState({
                                	pims: allPims,
					pimsLoading: false,
					taperRecs: allTaperRecs,
				});
				return;
			}
		);

		const medline_api_url = path + '/wp-json/pimsplus/v1/medlineconnect'
		const medlineBaseSearchUrl = `${medline_api_url}?`;

		const medlineSearchUrl = medlineBaseSearchUrl + '&rxcui=' + drugData.matchingDrugs[0].rxcui;


		axios.get(medlineSearchUrl)
			.then((res) => {
				this.setState({
					loading: false,
                                	medlineData: res.data.feed,
					medlineLoading: false,
					medlineMainTitle: res.data.feed.entry.length > 0 ? res.data.feed.entry[0].title._value : null,
					medlineMainSummary: res.data.feed.entry.length > 0 ? res.data.feed.entry[0].summary._value : null
				});

				//console.log(res.data.feed);
			}
		);
	}

	stripHTML = (toStrip) => {
		if (!toStrip || toStrip.length === 0) return "";

		return toStrip.replace(/(<([^>]+)>)/gi, "");
	}

	handleBack = () => {
		const drugSearch = '/drug-search/';
		this.props.history.push({
			pathname: `${drugSearch}`, 
		});
	}

	toggleReferences = () => {
		this.setState( { showReferences: !this.state.showReferences } );
	}

	toggleMedlineSummary = () => {
                this.setState( { medlineExpandSummary: !this.state.medlineExpandSummary } );
        }

	loadAllDrugForms = () => {
                this.setState( { drugFormsToLoad: 1000 } );
        }

	saveState = () => {
		const thisPath = this.props.location.pathname;
		const thisState = this.state;

		this.props.history.replace(
			{pathname: thisPath},
			thisState,
		);
	}

	render() {
		const { drugData, drugDataLoaded, drugFormsToLoad, blackBoxWarning } = this.state;

		return (
			<div className="drug-drilldown drugDetail">
				<div><button onClick={this.handleBack}>&lt; Return to Search</button></div>
				<div id="drug-detail-pagetitle">{this.state.drugName}</div>

				<div id="drug-detail-title">Medical Ingredient(s)</div>
				<div id="drug-detail-content-list">
				{drugDataLoaded && drugData.ingredients.map((ingredient, i) =>
 					{return(
 						<React.Fragment key={`${i}`}>
                                                               {i > 0 && "; "}
                                                       	{ingredient.ingredientName}
						</React.Fragment>);
                                	})
				}
				</div>
				{ this.renderMedline() }


                                <div id="drug-detail-title">Drug Forms and Monograph Links</div>
				<div id="drug-detail-content">
						{ blackBoxWarning &&
							<React.Fragment>
								<div id="alertHighlighted"><BiInfoCircle /> FDA blackbox warning. See monographs for details.<br /></div>
							</React.Fragment>
						}
						<ul>

						{drugDataLoaded && drugData.matchingDrugs.slice(0, drugFormsToLoad).map(matchingDrug =>
 						{return(
 							<li>
                                                        	{matchingDrug.drugFullName}&nbsp;(
								<Link onClick={this.saveState} to={"/fda-monographs/rxcui/" + matchingDrug.rxcui}>
									Monographs
								</Link>)
							</li>);
                                        	})
					    }
						{drugDataLoaded && drugData.matchingDrugs.length > drugFormsToLoad &&
							<li>
								<button onClick={ this.loadAllDrugForms }>
									+ see all { drugData.matchingDrugs.length } forms
								</button>
							</li>
						}
						</ul>

				</div>

				
				{ this.renderPims() }
				{ this.renderTaperRecs() }
				<div><button onClick={this.handleBack}>&lt; Return to Search</button></div>
			</div>
		)
	}

        renderMedline = () => {
                const { medlineLoading, medlineData, medlineMainTitle, medlineMainSummary } = this.state;

		const medlineMainSummaryNoHTML = this.stripHTML(medlineMainSummary);

                if ( medlineLoading ) {
                        return(
				<React.Fragment>
                	                <div id="drug-detail-title">Consumer Information (Provided By MedlinePlus.gov)</div>
	                                <div id="drug-detail-content">Loading...</div>
				</React.Fragment>
                        )
                } else if ( !(Object.keys(medlineData).length && medlineData.entry.length) ) {
                        return(
				<React.Fragment>
                	                <div id="drug-detail-title">Consumer Information (MedlinePlus.gov)</div>
        	                        <div id="drug-detail-content">No information is available. Please refer to monographs for information.</div>
				</React.Fragment>
                        )
                } else {
                        return (
			<React.Fragment>
                                <div id="drug-detail-title">Consumer Information for {this.stripHTML(medlineMainTitle)} (MedlinePlus.gov)</div>
				<div id="drug-detail-content">
						{
							this.state.medlineExpandSummary ?
	                                        	medlineMainSummaryNoHTML + " ":
							(medlineMainSummaryNoHTML.length > 300 ?
								medlineMainSummaryNoHTML.substring(0, 300) + "... "
								: medlineMainSummaryNoHTML)
						}
						<button onClick={ this.toggleMedlineSummary }>
							{ (this.state.medlineExpandSummary || medlineMainSummaryNoHTML.length < 300) ? "" : " + see more" }
						</button>
				</div>
                                <div id="drug-detail-subtitle">Additional MedlinePlus.gov Resources</div>
				<div id="drug-detail-content">
						{medlineData.entry.map(medlineEntry => {
                                                	return(
								<React.Fragment>
									<Link to ={{pathname: medlineEntry.link[0].href}} target="_blank">
	                                                        	<b>{medlineEntry.title._value} </b> <BiLinkExternal />
									</Link><br />
								</React.Fragment>
                                                        )
                                                })}
				</div>
			</React.Fragment>
                        );
                }

        };

        renderPims = () => {
                const { pimsLoading, pims } = this.state;

		if ( pimsLoading ) {
			return(
				<React.Fragment>
					<div id="drug-detail-title">PIMsPlus Notes</div>
					<div id="drug-detail-content">Loading...</div>
				</React.Fragment>
			)
		} else if ( pims.length === 0 ) {
			return (
				<React.Fragment>
					<div id="drug-detail-title">PIMsPlus Notes</div>
					<div id="drug-detail-content">This medication is currently not listed as a PIM; use standard prescribing guidelines.</div>
				</React.Fragment>
			);
		}
		else {
			return (
				<React.Fragment>
					<div id="drug-detail-title">PIMsPlus Notes</div>
				{ pims.map((pim, index) => {
					if (Object.keys(pim).length === 0 || pim.pim_note === undefined ) return ( null );

					return(
						<React.Fragment>
							<div id="drug-detail-subtitle">{ pim.genericingredient }</div>
							
							<div id="drug-detail-content" dangerouslySetInnerHTML={ {__html: pim.pim_note} } />
							{ (index + 1) === pims.length &&
								<React.Fragment>
								<button onClick={ this.toggleReferences }>{ this.state.showReferences ? "- Hide references" : "+ Show references" }</button>
								{ this.renderSources() } 
								</React.Fragment>
							}
						</React.Fragment>
					)}
				)}
				{ /*
					pimDisplayed ? (
							<tr>
								<td>PIMsPlus References</td>
								<td>
									<button onClick={ this.toggleReferences }>{ this.state.showReferences ? "Hide references" : "Show references" }</button>
									{ this.renderSources() } 
								</td>
							</tr>
					) : ( <></> )
				*/
				}
				{ /*
							<button onClick={ this.toggleReferences }>
								Toggle References { this.state.showReferences ? "true" : "false" }
							</button>
				*/}
				</React.Fragment>
			);
		}

        };

        renderSources = () => {
                const { pims } = this.state;
		if (!this.state.showReferences) return ( null );

		return (
			<React.Fragment>

			{

			pims.map(pim => {
				if ( Object.keys(pim).length === 0 || pim.sources === undefined  || !this.state.showReferences ) return ( null );
				return(
					<div dangerouslySetInnerHTML={ {__html: pim.sources} } />
				)
			} )
			}

			</React.Fragment>
		)
	}

	renderTaperRecs = () => {
                const { pimsLoading, taperRecs } = this.state;

		if (pimsLoading || taperRecs.length === 0 ) return ( null );

		return (
			<React.Fragment>

			<div id="drug-detail-title">Tapering Guides</div>
			<div id="drug-detail-content">
				{ taperRecs.map(rec => {
				//if (Object.keys(rec).length === 0 || rec.label === undefined ) return ( null );
	
					return(
						<React.Fragment>
							<Link to = {{pathname: rec.link }} target="_blank">
								<b>{rec.label} </b> <BiLinkExternal />
							</Link><br />
						</React.Fragment>
					)}
				)}
			</div>

			</React.Fragment>
		)
	}

}

export default DrugDetail;
