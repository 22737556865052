import React from 'react';
import axios from 'axios';
import './drugList.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

class DrugList extends  React.Component {

	constructor( props ) {
		super( props );

		this.state = {
			searchLetter: this.props.match.params.searchLetter,
			drugList: null,
		};

		this.loadList = this.loadList.bind(this);
		this.changeLetter = this.changeLetter.bind(this);
	}

	componentWillMount() {
		//if ( this.props.location.state !== undefined )
		//if ( this.props.location.state !== undefined && this.props.location.state.searchLetter !== '' )
		//	this.setState( this.props.location.state );
	}

	componentWillReceiveProps() {
		if (this.props.history.action == "POP") {
			this.setState(this.props.history.location.state);
		}
	}

	componentDidMount() {
		document.title = "Drug Index | PIMsPlus";
		this.loadList();
	}

	loadList() {
		const { searchLetter } = this.state;
		const api_key = '8E660ab9-da8a-46bd-8be2-5f7bfc42ca15'; // Doesn't matter if this is public
		//const passedvalues = queryString.parse(this.props.location.search);

                const search_api_url = '/wp-json/pimsplus/v1/druglist';

                const searchUrl = `${search_api_url}?api_key=${api_key}&q=${searchLetter}`;

		if (!this.state.drugList) {
	                axios.get(searchUrl)
        	                .then((res) => {
                	        this.setState({
					drugList: res.data.drugs,
        	                });
                	})
		}
	}

	handleSelect = (drug_uri_id) => {
		const drugDetail = '/drug-detail/';

		var drug_uri_id_enc = drug_uri_id;

		drug_uri_id_enc = drug_uri_id_enc.replace(/ /g,'___');
		drug_uri_id_enc = drug_uri_id_enc.replace(/\//g,'_and_');
		drug_uri_id_enc = encodeURIComponent(drug_uri_id_enc);

		this.props.history.push({
			pathname: `${drugDetail}${drug_uri_id_enc}/`,
			state:
				{
					drugName: drug_uri_id,
					drugDataLoaded: false,
					drugData: null,
				}
			}
		);
	}

	detailLink = (drug_uri_id) => {
		const drugDetail = '/drug-detail/';

		var drug_uri_id_enc = drug_uri_id;

		drug_uri_id_enc = drug_uri_id_enc.replace(/ /g,'___');
		drug_uri_id_enc = drug_uri_id_enc.replace(/\//g,'_and_');
		drug_uri_id_enc = encodeURIComponent(drug_uri_id_enc);

		return `${drugDetail}${drug_uri_id_enc}/`;
	}

	changeLetter = (letter) => {
		const { searchLetter, drugList } = this.state;

		/*
		this.props.history.replace({
			state: { searchLetter: searchLetter, drugList: drugList }
		});
		this.props.history.push({
			pathname: '/drug-list/' + letter + '/',
			state: { searchLetter: letter, drugList: null }
		});
		*/
		this.setState({ drugList: null, searchLetter: letter}, this.loadList);
	}

	renderLetterChoice = () => {
		var letters = [];

		for(var k = 0; k < 26; k++){
			var str = String.fromCharCode(k + 65);
			letters[k] = str;
		}
		return letters.map( (letter) => {
	        	return (<Link to={ '/drug-list/' + letter + '/' } onClick={ () => {this.changeLetter(letter)} } className="letter-chooser">{letter}</Link>);
	        	//return (<button onClick={ () => this.changeLetter(letter) } className="letter-chooser">{letter}</button>);
		});
	        //return (<button onClick={ () => this.changeLetter(str) }>{str}</button>);
	}


	render() {
		const { searchLetter, drugList } = this.state;

		return (
			<div>
				<div className="letter-chooser-container">
					{this.renderLetterChoice()}
				</div>
				<div className="drug-list">
				{ drugList == null && "Loading" }
				{ drugList != null && drugList.map( (drug) => {
					return (
						<div>
							<Link to={this.detailLink(drug.displayname)}>{drug.displayname}</Link>
						</div> );

				})}
				</div>
				<div className="letter-chooser-container">
					{this.renderLetterChoice()}
				</div>
			</div>
		)
	}
}

export default DrugList;
