import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

/*
const App = () => (
  <div className="App">
    <span className="App__Message">Hello,<br />World!</span>
  </div>
);
*/

const target = document.getElementById('pp-root');
//const target = document.querySelector('pp-root');

if (target) {
  ReactDOM.render(
    <React.StrictMode>
	  <BrowserRouter>
	      <App />
	  </BrowserRouter>
    </React.StrictMode>,
  target);

}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
