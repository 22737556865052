import React from 'react';
import Search from './components/search';
import DrugDetail from './components/drugDetail';
import DrugList from './components/drugList';
import fdaMonographList from './components/fdaMonographList';
import { Route, Switch } from 'react-router-dom';
//import 'react-app-polyfill/ie11';

class App extends React.Component {

	render() {
		return (
			<Switch>
				<Route path='/drug-search/' component={Search} />
				<Route path='/drug-list/:searchLetter' component={DrugList} />
				<Route path='/drug-detail/:drugName' component={DrugDetail} />
				<Route path='/fda-monographs/rxcui/:rxcui' component={fdaMonographList} />
			</Switch>
		);
	}
}

export default App;
