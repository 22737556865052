import React from 'react';
import './search.css';
import axios from 'axios';
import PageNavigation from './pageNavigation'; 
import Loader from '../img/loader.gif';
import { debounce } from 'lodash';
//import Moment from 'react-moment';
import 'react-app-polyfill/ie11';

class Search extends  React.Component {


	constructor( props ) {
		super( props );

		this.inputRef = React.createRef();

		this.state = {
			query: '',
                        results: {},
			genericsOnly: false,
                        loading: false,
                        message: '',
			totalResults: 0,
			totalPages: 0,
			currentPageNo: 0,
		};
	
		//this.handleOnInputChange = this.handleOnInputChange.bind(this);
		//this.runQueryAndUpdateIfValid = this.runQueryAndUpdateIfValid.bind(this);
		//this.throttleFetchSearchResults = this.throttleFetchSearchResults.bind(this);
		//this.fetchSearchResults = this.fetchSearchResults.bind(this);
		//this.throttledFetchSearchResults = debounce(this.fetchSearchResults, 2000);
		//this.throttledHandleOnInputChange = debounce(this.handleOnInputChange, 2000);
		//this.throttledTRunQueryAndUpdateIfValid = debounce(this.runQueryAndUpdateIfValid, 2000);
		//this.fetchSearchResults = this.fetchSearchResults.bind(this);
	}

	componentDidMount() {
		document.title = "Drug Search | PIMsPlus";
		this.inputRef.current.focus();
	}

	componentWillMount() {
		//if ( this.props.location.state !== undefined )
		if ( this.props.location.state !== undefined && this.props.location.state.query !== '' )
			this.setState( this.props.location.state );
	}

	handleOnCheckboxChange = (event) => {
		const genericsOnly = !this.state.genericsOnly;
		const query = this.state.query;

		this.setState({ genericsOnly: !this.state.genericsOnly });

		this.runQueryAndUpdateIfValid(query, genericsOnly);

	}

	handleOnInputChange = (event) => {
		const genericsOnly = this.state.genericsOnly;
		const query = event.target.value;

		this.runQueryAndUpdateIfValid(query, genericsOnly);
	};


	runQueryAndUpdateIfValid = (query, genericsOnly = false) => {
		if ( query.length < 3 || ! query ) {
			this.setState({ query, results: {}, totalResults: 0, totalPages:0, currentPageNo:0, message: '' } );
		} else {
			this.setState({ query, loading: true, message: '' },
				() => {
					this.fetchSearchResults(1, query, genericsOnly);
				} 
			);
		}
	};

/**
 * Get the Total Pages count.
 *
 * @param total
 * @param denominator Count of results per page
 * @return {number}
 */
	getPagesCount = (total, denominator) => {
		const divisible = total % denominator === 0;
		const valueToBeAdded = divisible ? 0 : 1;
		return Math.floor(total / denominator) + valueToBeAdded;
	};

/**
 * Fetch the search results and update the state with the result.
 *
 * @param {int} updatedPageNo Updated Page No.
 * @param {String} query Search Query.
 *
 */
	fetchSearchResults = debounce( (updatedPageNo = '', query, genericsOnly = false) => {
                //var path = 'http://undefined';
                //if (typeof window !== 'undefined') {
                        // API is part of plugin, so it's always self-hosted
                //        path = window.location.origin;
                //}
		const api_url = '/wp-json/pimsplus/v1/drugs';

		const pageNumber = updatedPageNo ? `${updatedPageNo}` : '';	// By default the limit of results is 20
		const api_key = '8E660ab9-da8a-46bd-8be2-5f7bfc42ca15'; // Doesn't matter if this is public
		const searchUrl = `${api_url}?api_key=${api_key}&q=${query}&page=${pageNumber}&genericsOnly=${genericsOnly}`;

		if (this.cancel) {
			// Cancel the previous request before making a new request
			this.cancel.cancel();
		}
		// Create a new CancelToken
		this.cancel = axios.CancelToken.source();
		axios.get(searchUrl, {
			cancelToken: this.cancel.token,
			})
			.then((res) => {
				const total = res.data.total;
				const perpage = res.data.limit_per_page;
				const totalPagesCount = this.getPagesCount( total, perpage );
				const resultNotFoundMsg = !res.data.drugs.length
					? (this.state.genericsOnly ? 'There are no matching search results (you are currently searching generics only).' : 'There are no matching search results. Try another search.')
					: '';
			this.setState({
				results: res.data.drugs,
				totalResults: res.data.total,
				currentPageNo: updatedPageNo,
				totalPages: totalPagesCount,
				message: resultNotFoundMsg,
				loading: false,
			});
		})
		.catch((error) => {
			if ((error.response || error.request) && (axios.isCancel(error) || error)) {
				console.log(error.response);
				this.setState({
					loading: false,
					message: 'Failed to fetch results, please try again later.',
					//message: searchUrl,
				});
			}
		});
	}, 260 ); // last arg is debounce throttle, in ms

	handleRowClick = (drug_uri_id, drug_data) => {
		const drugDetail = '/drug-detail/';
		const thisPath = this.props.location.pathname;
		const thisState = this.state;

		var drug_uri_id_enc = drug_uri_id;

		drug_uri_id_enc = drug_uri_id_enc.replace(/ /g,'___');
		drug_uri_id_enc = drug_uri_id_enc.replace(/\//g,'_and_');
		drug_uri_id_enc = encodeURIComponent(drug_uri_id_enc);

		this.props.history.replace(
			{pathname: thisPath},
			thisState,
		);
		this.props.history.push({
			pathname: `${drugDetail}${drug_uri_id_enc}/`,
			state:
				{
					drugName: drug_uri_id,
					drugDataLoaded: true,
					drugData: drug_data,
				}

			}
		);
	}

/**
 * Fetch results according to the prev or next page requests.
 *
 * @param {String} type 'prev' or 'next'
 */
	handlePageClick = (type) => {
		//event.preventDefault();
		const genericsOnly = this.state.genericsOnly;
		const updatedPageNo =
			      'prev' === type
				      ? this.state.currentPageNo - 1
				      : this.state.currentPageNo + 1;
		if (!this.state.loading) {
			this.setState({ loading: true, message: '' }, () => {
				// Fetch previous 20 Results
				this.fetchSearchResults(updatedPageNo, this.state.query, genericsOnly);
			});
		}
	};

	renderSearchResults = () => {
		const {results} = this.state;
		if (Object.keys(results).length && results.length) {
			return (
				<div id="search-results-container">
					<table>
					<thead>
						<tr>
							<th id="search-results-drugrow">Drug</th>
							<th id="search-results-ingrow">Ingredient(s)</th>
						</tr>
					</thead>
					<tbody>
					{results.map((result) => {
						return (
								<tr onClick={() => {this.handleRowClick(result.displayname, result)}}>
									<td data-label="">{result.displayname}</td>
									<td data-label="Ingredient(s)">{result.ingredients.map((ingredient, i) =>  
										{return(
											<React.Fragment key={`${i}`}>
											{i > 0 && "; "}
											{ingredient.ingredientName}
											</React.Fragment>);
										})
									    }
									</td>
								</tr>
						);
					})}
					</tbody>
					</table>
				</div>
			);
		}
	};


	render() {
		const { query, loading, message, currentPageNo, totalPages } = this.state;
		const showPrevLink = 1 < currentPageNo;
		const showNextLink = totalPages > currentPageNo;

		return (
			<div className="container">
				{/*Heading*/}
				<div>
					<h2 className="heading">PIMsPlus Drug Search</h2>
				</div>
				{/*Search Input*/}
				<div>
					<label className="search-label" htmlFor="search-input">
						<input
							type="search"
							value={query}
							id="search-input"
							placeholder="Enter search term..."
							autocomplete="off"
							ref={this.inputRef}
							onChange={this.handleOnInputChange}
						/>
						<i className="fa fa-search search-icon"/>
					</label>
				</div>
				<div>
					<label className="search-generics" htmlFor="search-generics">
						<input
							type="checkbox"
							checked={this.state.genericsOnly}
							id="search-generics"
							onChange={this.handleOnCheckboxChange}
						/>
						Limit search to generics only
					</label>
				</div>
			{/*Error Message*/}
			{ !loading && message && <p className="message">{message}</p> }

			{/*Loader*/}
			<img src={Loader} className={`search-loading ${loading ? 'show' : 'hide' }`}  alt="loading" />

			{/*Result*/}
			{ this.renderSearchResults() }

			{/*Navigation Bottom*/}
			<PageNavigation
				loading={loading}
				showPrevLink={showPrevLink}
				showNextLink={showNextLink}
				handlePrevClick={() => this.handlePageClick('prev')}
				handleNextClick={() => this.handlePageClick('next')}
			/>
			</div>
		)
	}
}

export default Search;
